<template>
	<div>
		<el-row>
			<el-col>
				<el-form :inline='true'>
					<el-form-item label="关键字:">
						<el-input v-model='filters.keyWord' placeholder="请输入名称" clearable @clear='handleClear'></el-input>
					</el-form-item>
					<el-form-item label="单位名称:">
						<el-input v-model='filters.socialUnitName' placeholder="请输入名称" clearable @clear='handleClear'></el-input>
					</el-form-item>
					<el-form-item label="岗位名称:">
						<el-select v-model="filters.stationId" style="width: 160px" clearable placeholder="请选择">
							<el-option
									v-for="item in stationList"
									:key="item.Id"
									:label="item.Name"
									:value="item.Id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="行业名称:">
						<el-select v-model="filters.tradeId" style="width: 160px" clearable placeholder="请选择">
							<el-option
									v-for="item in tradeList"
									:key="item.Id"
									:label="item.Name"
									:value="item.Id"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否缴费:">
						<el-select v-model="filters.isPayed" style="width: 120px" clearable placeholder="请选择">
							<el-option
									v-for="item in options"
									:key="item.value"
									:label="item.name"
									:value="item.value"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否启用:">
						<el-select v-model="filters.isEnable" style="width: 120px" clearable placeholder="请选择">
							<el-option
									v-for="item in options3"
									:key="item.value"
									:label="item.name"
									:value="item.value"
							>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<el-table
				:data="tableData"
				border
				v-loading="listLoading"
				@current-change="selectCurrentRow"
				highlight-current-row
				style="width: 100%;margin-bottom: 20px"
		>
			<el-table-column prop="Code" type="index"  label="序号" align="center" :index='indexMethod' width="80"></el-table-column>
			<el-table-column prop="Name" label="姓名" align="center" width="100"></el-table-column>
			<el-table-column prop="PhoneNumber" label="手机号" align="center" width="120"></el-table-column>
			<el-table-column prop="Name" label="身份证号" align="center" width=""></el-table-column>
			<el-table-column prop="StationName" label="岗位名称" align="center" width=""></el-table-column>
			<el-table-column prop="TradeName" label="行业名称" align="center" width=""></el-table-column>
			<el-table-column prop="UnitName" label="单位名称" align="center" width="240"></el-table-column>
			<el-table-column prop="OrderTotal" label="需缴费金额（元）" align="center" width="120">
				<template slot-scope="scope">
					{{(scope.row.OrderTotal / 100).toFixed(2)}}
				</template>
			</el-table-column>
			<el-table-column prop="IsPayed" label="是否缴费" align="center" width="120">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.IsPayed" type="success">已缴费</el-tag>
					<el-tag v-else type="danger">未缴费</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="Name" label="启用标识" align="center" width="">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.IsAllowStudy" type="success">启用</el-tag>
					<el-tag v-else type="danger">禁用</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="CreateTime" label="创建时间" align="center" width=""></el-table-column>
			<el-table-column prop="Name" label="学习记录" align="center" width="">
				<template slot-scope="scope">
					<el-button type="text" @click="preview(scope.row)">查看</el-button>
				</template>
			</el-table-column>
			
		</el-table>
		<!--底部工具条-->
		<el-col :span="24" class="pageBar" style="margin:20px 0">
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pages.pageIndex"
					:page-sizes="pages.pageArr"
					:page-size="pages.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="pages.dataCount">
			</el-pagination>
		</el-col>
		<!--	新增字典    -->
		<el-dialog
				title="学习记录"
				:visible.sync="viewDialog"
				v-model="viewDialog"
				:close-on-click-modal="false"
				width="70%"
		>
			<template>
					合格学习时长：{{detailNeedData&&detailNeedData.QualifiedLineDuration?detailNeedData.QualifiedLineDuration:'0'}}
				&nbsp;&nbsp;&nbsp;&nbsp;
					学习时长：{{detailNeedData&&detailNeedData.LearnDuration?detailNeedData.LearnDuration:'0'}}
			</template>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="视频学习记录" name="0">
					<el-table
							:data="tableDataStudy"
							border
							v-loading="listStudyLoading"
							highlight-current-row
							style="width: 100%;margin-bottom: 20px"
					>
<!--						<el-table-column prop="Code" type="index"  label="序号" align="center"  width="80"></el-table-column>-->
						<el-table-column prop="StudyDate" label="学习日期" align="center" width=""></el-table-column>
						<el-table-column prop="VideoName" label="学习视频" align="center" width=""></el-table-column>
						<el-table-column prop="LearnDuration" label="学习时长" align="center" width="">
							<template slot-scope="scope">
								{{formatSeconds(scope.row.LearnDuration)}}
							</template>
						</el-table-column>
					</el-table>
					<el-col :span="24" class="pageBar" style="margin:20px 0">
						<el-pagination
								@size-change="handleSizeChangeStudy"
								@current-change="handleCurrentChangeStudy"
								:current-page="pagesStudy.pageIndex"
								:page-sizes="pagesStudy.pageArr"
								:page-size="pagesStudy.pageSize"
								layout="total, sizes, prev, pager, next, jumper"
								:total="pagesStudy.dataCount">
						</el-pagination>
					</el-col>
				</el-tab-pane>
				<el-tab-pane label="试题练习记录" name="1">
					<el-table
							:data="tableDataStudy"
							border
							v-loading="listStudyLoading"
							highlight-current-row
							style="width: 100%;margin-bottom: 20px"
					>
<!--						<el-table-column prop="Code" type="index"  label="序号" align="center"  width="80"></el-table-column>-->
						<el-table-column prop="StudyDate" label="学习日期" align="center" width=""></el-table-column>
						<el-table-column prop="VideoName" label="练习题数" align="center" width=""></el-table-column>
						<el-table-column prop="LearnDuration" label="学习时长" align="center" width="">
							<template slot-scope="scope">
								{{formatSeconds(scope.row.LearnDuration)}}
							</template>
						</el-table-column>
					</el-table>
					<el-col :span="24" class="pageBar" style="margin:20px 0">
						<el-pagination
								@size-change="handleSizeChangeStudy"
								@current-change="handleCurrentChangeStudy"
								:current-page="pagesStudy.pageIndex"
								:page-sizes="pagesStudy.pageArr"
								:page-size="pagesStudy.pageSize"
								layout="total, sizes, prev, pager, next, jumper"
								:total="pagesStudy.dataCount">
						</el-pagination>
					</el-col>
				</el-tab-pane>
				<el-tab-pane label="模拟考试记录" name="2">
					<el-table
							:data="tableDataStudy"
							border
							v-loading="listStudyLoading"
							highlight-current-row
							style="width: 100%;margin-bottom: 20px"
					>
<!--						<el-table-column prop="Code" type="index"  label="序号" align="center"  width="80"></el-table-column>-->
						<el-table-column prop="StudyDate" label="考试日期" align="center" width=""></el-table-column>
						<el-table-column prop="ExamName" label="试卷名称" align="center" width=""></el-table-column>
						<el-table-column prop="ExamDuration" label="考试用时" align="center" width="">
							<template slot-scope="scope">
								{{formatSeconds(scope.row.ExamDuration)}}
							</template>
						</el-table-column>
						<el-table-column prop="TotalScore" label="得分" align="center" width=""></el-table-column>
						<el-table-column prop="isQualified" label="是否及格" align="center" width="">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.isQualified " type="success">及格</el-tag>
								<el-tag v-else type="danger">不及格</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="QuestionCount" label="答题数" align="center" width=""></el-table-column>
						<el-table-column prop="LearnDuration" label="学习时长" align="center" width="">
							<template slot-scope="scope">
								{{formatSeconds(scope.row.LearnDuration)}}
							</template>
						</el-table-column>
					</el-table>
					<el-col :span="24" class="pageBar" style="margin:20px 0">
						<el-pagination
								@size-change="handleSizeChangeStudy"
								@current-change="handleCurrentChangeStudy"
								:current-page="pagesStudy.pageIndex"
								:page-sizes="pagesStudy.pageArr"
								:page-size="pagesStudy.pageSize"
								layout="total, sizes, prev, pager, next, jumper"
								:total="pagesStudy.dataCount">
						</el-pagination>
					</el-col>
				</el-tab-pane>
			</el-tabs>
		
			<div slot="footer" class="dialog-footer">
				<el-button @click="viewDialog = false">取 消</el-button>
				<el-button type="primary" @click="viewDialog = false">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import {
	changeStudentLearnRight,
	getDeletedStaffsAuthoritySetting,
	getLearnRecordListPage, getStationTradeList, getStudyRecordDetail
} from '../../api/api'
import util from "../../../util/date";
import Qs from 'qs';
export default {
	components: {Toolbar},
	data() {
		return {
			activeName: '0',
			pages : {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			pagesStudy : {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			pagesQuestion : {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			pagesSimulation : {
				pageSize: 20,//默认每页条数
				pageArr: [10, 20, 30, 40],//默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
			viewDialog : false,
			detailNeedData:undefined,
			dictionaryList:[],
			filters: {
				stationId:undefined,//岗位ID
				tradeId:undefined,//行业信息ID
				keyWord:undefined,//搜索关键字
				socialUnitName:undefined,//单位名称
				isPayed:undefined,//是否缴费 0:未缴费 1:已缴费
				isEnableLearnRight:undefined,//是否启用学习权限 0:未启用 1:已启用
				isEnable: undefined,
			},
			stationList:[],
			tradeList:[],
			options3: [
				{value:1,name:'已启用'},
				{value:0,name:'未启用'},
			],
			options: [
				{value:1,name:'已缴费'},
				{value:0,name:'未缴费'},
			],
			buttonList: [],
			tableData: [],
			tableDataStudy: [],
			tableDataQuestion: [],
			tableDataSimulation: [],
			listLoading: false,
			listStudyLoading: false,
			listQuestionLoading: false,
			listSimulationLoading: false,
			needData:undefined,
		}
	},
	methods: {
		formatSeconds  (seconds)  {
			const hours = Math.floor(seconds / 3600);
			const minutes = Math.floor((seconds % 3600) / 60);
			const remainingSeconds = seconds % 60;
			return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
		},
		handleClick(tab, event) {
			console.log(tab, event);
		},
		//查看
		preview(row){
			this.detailNeedData = row
			this.getStudyRecordDetail(row)
			this.viewDialog = true;
		},
		//获取学习记录
		async getStudyRecordDetail(row){
			this.listStudyLoading = true
			let params = {
				studentAccountId: row.StudentAccountId,//学生Id
				pageIndex:this.pagesStudy.pageIndex,
				pageSize:this.pagesStudy.pageSize,
				studentLearnRecordId:row.Id,//报名学习记录id
				searchType:0,//0:视频学习记录，1:试题练习记录，2:模拟考试记录
			}
			if (this.activeName==='0'){
				params.searchType = 0
			} else if (this.activeName==='1'){
				params.searchType = 1
			} else if (this.activeName==='2'){
				params.searchType = 2
			}
			getStudyRecordDetail(params).then(res=>{
				if(res.data.Success){
					this.tableDataStudy = res.data.Response.Data
					this.pagesStudy.dataCount = res.data.Response.DataCount
				}
				this.listStudyLoading = false
			})
		},
		
		// 获取行业名称
		async getTradeTypeData() {
			let params = {
			dataType:0,
			}
			const res = await getStationTradeList(params)
			if (res.data.Success) {
				this.tradeList = res.data.Response
			}
		},
		//获取岗位名称
		async getStationList() {
			let params = {
				dataType :1,
			}
			const res = await getStationTradeList(params)
			if (res.data.Success) {
				this.stationList = res.data.Response
			}
		},
		callFunction(item) {
			this[item.Func].apply(this, item);
		},
		// 清除input框，重新获取数据
		handleClear(){
			this.getDataList()
		},
		// 获取分页列表
		getDataList() {
			this.listLoading = true
			let params = {
				pageIndex:this.pages.pageIndex,//当前页
				pageSize:this.pages.pageSize,//每页条数
				stationId:this.filters.stationId,//岗位ID
				tradeId:this.filters.tradeId,//行业信息ID
				keyWord:this.filters.keyWord,//搜索关键字
				socialUnitName:this.filters.socialUnitName,//单位名称
				isPayed:this.filters.isPayed,//是否缴费 0:未缴费 1:已缴费
				isEnableLearnRight:this.filters.isEnable,//是否启用学习权限 0:未启用 1:已启用
			}
			getLearnRecordListPage(params).then((res) => {
				let result = res.data
				if(result.Success){
					this.tableData = result.Response.Data
					this.pages.dataCount = result.Response.DataCount
					this.listLoading = false;
				}else{
					this.listLoading = false;
				}
			})
		},
		// 查询
		getListData() {
			this.pages.pageIndex = 1;
			this.pages.pageSize = 20;
			this.getDataList();
		},
		//启用、禁用
		enableOrDisable(){
			if (this.needData){
				this.$confirm(`确定${!this.needData.IsAllowStudy?'启用':'禁用'}？`,"提示",{
				}).then(() => {
					let params = {
						Id: this.needData.Id,
						IsAllowStudy:!this.needData.IsAllowStudy
					}
					changeStudentLearnRight(params).then((res) => {
						if (res.data.Success){
							this.$message({
								type: 'success',
								message: res.data.Message
							});
							this.getListData()
						} else {
							this.$message({
								type: 'error',
								message: res.data.Message
							});
						}
					})
				})
			} else {
				this.$message({
					type: 'error',
					message: '请选择一条数据'
				});
			}
			
		},
		
		// 点击table某一行
		selectCurrentRow(val) {
			this.needData = val
		},
		// 分页
		handleCurrentChange(val) {
			this.pages.pageIndex = val;
			this.getDataList();
		},
		handleSizeChange(val){
			this.pages.pageSize = val;
			this.getDataList();
		},
		// 分页
		handleCurrentChangeStudy(val) {
			this.pages.pageIndex = val;
			this.getStudyRecordDetail(this.detailNeedData);
		},
		handleSizeChangeStudy(val){
			this.pages.pageSize = val;
			this.getStudyRecordDetail(this.detailNeedData);
		},
		
		//时间格式化
		formatCreateTime: function(row, column) {
			return !row.CreateTime || row.CreateTime == ""
					? ""
					: util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
		},
		// 表格数据分页条数累计
		indexMethod(index) {
			let currentPage = this.pages.pageIndex
			let pageSize = this.pages.pageSize
			return (index + 1) + (currentPage - 1) * pageSize
		},
	},
	watch: {
		"activeName" (val) {
			this.getStudyRecordDetail(this.detailNeedData)
		}
	},
	mounted() {
		this.getStationList()
		this.getTradeTypeData()
		let routers = window.localStorage.router
				? JSON.parse(window.localStorage.router)
				: [];
		this.buttonList = getButtonList(this.$route.path, routers);
		this.getListData();
	},
}
</script>

<style lang="stylus" scoped>
.el-textarea>>>.el-textarea__inner {
	min-height 100px!important;
}
</style>
<style>
.el-tooltip__popper {
	max-width: 500px;
}
</style>